<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'

defineProps<{
  count: number
  loading: boolean
}>()

const emit = defineEmits<{
  (event: 'action', data: Dialogs['business/DialogRemoveMembers']['contextData']): void
  (event: 'close'): void
}>()

const { t } = useI18n()
</script>

<template>
  <base-dialog
    max-width="500"
    action-color="error"
    :action-text="t('business.members.remove_member')"
    :cancel-text="t('business.members.keep_members', { count }, count)"
    :title="t('business.members.remove_member_header')"
    :loading="loading"
    @action="emit('action')"
    @close="emit('close')"
  >
    <div v-if="count === 1">
      {{ t('business.members.remove_member_prompt_one') }}
    </div>
    <div v-else>
      {{ t('business.members.remove_member_prompt', { count }) }}
    </div>
  </base-dialog>
</template>
